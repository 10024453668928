export default defineNuxtRouteMiddleware(async (to, _from) => {
  const keywordSeedingStore = useKeywordSeedingStore();

  const { id, productgroup, shopname } = to.params;

  await keywordSeedingStore.getReport(id as string);

  if (!keywordSeedingStore.currentReport) {
    return navigateTo({
      name: "keyword-seeding-report-list",
    });
  }

  await keywordSeedingStore.fetchProductGroups(id as string);

  const index = keywordSeedingStore.productGroups.findIndex(
    (productGroup) =>
      productGroup.product_group.product_group_number === Number(productgroup)
  );

  if (!productgroup || index === -1) {
    return navigateTo({
      name: "keyword-seeding-reports-id-productgroup",
      params: {
        productgroup:
          keywordSeedingStore.productGroups[0]?.product_group.product_group_number,
        id: id,
      },
    });
  }
});
